/* #root {
  background: url("../patterns/circuit.png") #012e56;
  background-blend-mode: multiply;
  height: 130vh;
} */

body {
    height: 100vh;
  }
  
  .grow {
    transition: all 0.2s ease-in-out;
  }
  
  .grow:hover {
    transform: scale(1.1);
  }
  